import {
    FormDescription,
    FormField,
    FormGroup,
    FormItem,
    FormLabel,
    FormMessage,
    FormValue,
    Input, Select
} from "../../Form/Form";
import {Combobox, ComboboxInput, ComboboxItem, ComboboxList} from "../../Form/Combobox/Combobox";
import {formatDateIfNotEmpty} from "../../../actions/date.actions";
import {getLanguageName} from "../../../actions/magicNumber.actions";
import React, {useContext} from "react";
import {FleetWizardContext} from "../FleetWizardContext";


const DriverTab = ({createDriverForm, createFuelCardForm, createCarForm, onSubmit, children, tab, labels}) => {
    const { state, dispatch } = useContext(FleetWizardContext);

    const { selectedObjects, objects, selectEntries } = state;

    const { selectedOrganisation, selectedDriver, selectedCar } = selectedObjects;
    const {cars, drivers, organisations } = objects;

    const { languages, salutations, phoneTypes } = selectEntries;

    function onSelectDriver(id) {
        const driver = drivers.find(driver => driver?.childCustomer?.id === id);
        dispatch({
            type: 'UPDATE_SELECTED_DRIVER',
            payload: driver || null,
        });
        createDriverForm.setValue("parentCustomer", driver?.parentCustomer || "");
        createDriverForm.setValue("childCustomer.firstName", driver?.childCustomer?.firstName || "");
        createDriverForm.setValue("childCustomer.lastName", driver?.childCustomer?.lastName || "");
        createDriverForm.setValue("childCustomer.email", driver?.childCustomer?.email || "");
        createDriverForm.setValue("childCustomer.birthday", driver?.childCustomer?.birthday || null);
        createDriverForm.setValue("childCustomer.salutation.id", driver?.childCustomer?.salutation.id ?? null);
        createDriverForm.setValue("childCustomer.phone.countryCode", driver?.childCustomer?.phone?.countryCode || "");
        createDriverForm.setValue("childCustomer.phone.cli", driver?.childCustomer?.phone?.cli || "");
        createDriverForm.setValue("childCustomer.phone.areaCode", driver?.childCustomer?.phone?.areaCode || "");
        createDriverForm.setValue("childCustomer.phone.phone", driver?.childCustomer?.phone?.phone || "");
        createDriverForm.setValue("childCustomer.phone.phoneType.id", driver?.childCustomer?.phone?.phoneType?.id || "");
        createDriverForm.setValue("childCustomer.mainAddress.street", driver?.childCustomer?.mainAddress?.street || "");
        createDriverForm.setValue("childCustomer.mainAddress.zip", driver?.childCustomer?.mainAddress?.zip || "");
        createDriverForm.setValue("childCustomer.mainAddress.city", driver?.childCustomer?.mainAddress?.city || "");
        createDriverForm.setValue("childCustomer.mainAddress.houseNumber", driver?.childCustomer?.mainAddress?.houseNumber || "");

        //createDriverForm.setValue("childCustomer.mainAddress.country.id", driver?.childCustomer?.mainAddress?.country?.id || null);

        // Trigger  all fields we changed above (to validate)
        createDriverForm.trigger(["parentCustomer", "childCustomer.firstName", "childCustomer.lastName", "childCustomer.email", "childCustomer.salutation.id", "childCustomer.birthday", "childCustomer.phone.phoneType.id", "childCustomer.phone.countryCode", "childCustomer.phone.cli", "childCustomer.phone.areaCode", "childCustomer.phone.phone", "childCustomer.mainAddress.street", "childCustomer.mainAddress.zip", "childCustomer.mainAddress.city", "childCustomer.mainAddress.houseNumber", "childCustomer.mainAddress.country.id"])
    }

    function handleCheckbox(e) {
        createDriverForm.setValue("existingDriver", e.target.checked);
        // If the checkbox is unchecked, reset the selected driver (otherwise a bug/exploit can occur)
        if (!e.target.checked) {
            dispatch({
                type: 'UPDATE_SELECTED_DRIVER',
                payload: null,
            });

            createDriverForm.setValue("parentCustomer", null);
            createDriverForm.setValue("childCustomer.firstName", "");
            createDriverForm.setValue("childCustomer.lastName", "");
            createDriverForm.setValue("childCustomer.email", "");
            createDriverForm.setValue("childCustomer.birthday", null);
            createDriverForm.setValue("childCustomer.salutation.id", null);
            createDriverForm.setValue("childCustomer.phone.countryCode",  "");
            createDriverForm.setValue("childCustomer.phone.cli", "");
            createDriverForm.setValue("childCustomer.phone.areaCode",  "");
            createDriverForm.setValue("childCustomer.phone.phone", "");
            createDriverForm.setValue("childCustomer.phone.phoneType.id", "");
            createDriverForm.setValue("childCustomer.mainAddress.street", "");
            createDriverForm.setValue("childCustomer.mainAddress.zip",  "");
            createDriverForm.setValue("childCustomer.mainAddress.city", "");
            createDriverForm.setValue("childCustomer.mainAddress.houseNumber",  "");
        }
    }


    if (tab !== 1) return null
    return (
        <form onSubmit={createDriverForm.handleSubmit(onSubmit)} className="form">

            <div className="modal-tab-content" data-selected={tab === 1} id={"modal-tab-1"}>
                {/*Error Message*/}
                {children[0]}


                <FormField
                    control={createDriverForm.control}
                    name="carId"
                    render={({field, error}) => (<FormItem>
                        <FormLabel {...error} >
                            {labels?.fleetWizard?.form?.selectCarTitle} *
                        </FormLabel>

                        <Combobox
                            selected={selectedCar?.additionalCarInformation?.baseData?.carId}
                            onSelect={(id) => {
                                const car = cars.find(car => car?.additionalCarInformation?.baseData?.carId === id);
                                dispatch({
                                    type: 'UPDATE_SELECTED_CAR',
                                    payload: car,
                                });
                                createDriverForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "");
                                createFuelCardForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "");
                                createDriverForm.trigger(["carId"])
                                // Maybe conditionally trigger carId field for fuelCardForm, otherwise might be a bit confusing
                                // If field was RED on FuelCard Tab, then you select it on Driver Tab but it remains Red despite
                                // being filled
                            }}
                        >
                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                           selectedValue={`${selectedCar?.brand} ${(selectedCar?.type || "")} (${selectedCar?.additionalCarInformation?.baseData?.vin})`}
                                           data-invalid={!!error?.message}
                            />
                            <ComboboxList
                                noResults={<ComboboxItem
                                    disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                            >
                                {cars?.map(car => (<ComboboxItem key={car?.additionalCarInformation?.baseData?.carId}
                                                                 value={car?.additionalCarInformation?.baseData?.carId}>
                                    {`${car?.brand} ${(car?.type || "")} (${car?.additionalCarInformation?.baseData?.vin})`}
                                </ComboboxItem>))}
                            </ComboboxList>
                        </Combobox>

                        <FormMessage message={error?.message}/>

                        <FormDescription>
                            {labels?.fleetWizard?.form?.selectCarDescription}
                        </FormDescription>

                    </FormItem>)}
                />


                <FormField
                    control={createDriverForm.control}
                    name="existingDriver"
                    render={({field, error}) => (<FormItem>
                        <FormLabel linkedCheckBoxId="existingDriverCheckbox" {...error} >
                            {labels?.fleetWizard?.form?.existingDriver}
                        </FormLabel>
                        <Input id="existingDriverCheckbox" {...field} {...error}
                               checked={createDriverForm.watch("existingDriver")}
                               type="checkbox"
                               onChange={handleCheckbox}
                        />
                    </FormItem>)}
                />

                {createDriverForm.watch("existingDriver") && (
                    <FormField
                        control={createDriverForm.control}
                        name="parentCustomer"
                        render={({field, error}) => (
                            <FormItem>
                                <Combobox
                                    selected={selectedDriver?.childCustomer?.id}
                                    onSelect={onSelectDriver}
                                >
                                    <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                   selectedValue={selectedDriver?.childCustomer?.firstName + " " + selectedDriver?.childCustomer?.lastName}
                                                   data-invalid={!!error?.message}
                                    />
                                    <ComboboxList
                                        noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                    >
                                        {drivers?.map(driver => (<ComboboxItem key={driver?.childCustomer?.id}
                                                                               value={driver?.childCustomer?.id}>
                                            {driver?.childCustomer?.firstName} {driver?.childCustomer?.lastName}
                                        </ComboboxItem>))}
                                    </ComboboxList>
                                </Combobox>

                                <FormMessage message={error?.message}/>
                                <FormDescription>
                                    {labels?.fleetWizard?.form?.selectDriverDescription}
                                </FormDescription>

                            </FormItem>)}
                    />
                )}


                {(!createDriverForm.watch("existingDriver") || (createDriverForm.watch("existingDriver") && selectedDriver)) && (<>

                    <FormField
                        control={createDriverForm.control}
                        name="parentCustomer"
                        render={({field, error}) => (
                            <FormItem
                                hidden={(organisations?.length === 1 || organisations?.length === 0) && !createDriverForm.watch("existingDriver")}
                            >
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.companyName} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.parentCompanyName}
                                        </FormValue>
                                    ) : (
                                        <Combobox
                                            selected={selectedOrganisation?.id}
                                            onSelect={(id) => {
                                                const organisation = organisations.find(organisation => organisation?.id === id);
                                                dispatch({
                                                    type: 'UPDATE_SELECTED_ORGANISATION',
                                                    payload: organisation,
                                                });
                                                createDriverForm.setValue("parentCustomer", organisation?.id || null);
                                                createCarForm.setValue("organisation", organisation?.id || null);
                                                createFuelCardForm.setValue("companyData", organisation || {
                                                    id: null,
                                                    customerId: null,
                                                });

                                                createFuelCardForm.trigger(["companyData.customerId", "companyData"])
                                                createDriverForm.trigger(["parentCustomer"])

                                            }}
                                        >
                                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                           selectedValue={`${selectedOrganisation?.companyName}`}
                                                           disabled={organisations?.length === 1 || organisations?.length === 0}
                                                           data-invalid={!!error?.message}
                                            />
                                            <ComboboxList
                                                noResults={<ComboboxItem
                                                    disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                            >
                                                {organisations?.map(organisation => (
                                                    <ComboboxItem key={organisation?.id}
                                                                  value={organisation?.id}>
                                                        {`${organisation?.companyName}`}
                                                    </ComboboxItem>))}
                                            </ComboboxList>
                                        </Combobox>
                                    )
                                }

                                <FormMessage message={error?.message}/>

                            </FormItem>)}
                    />


                    <FormGroup>
                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.firstName"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.firstName} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.firstName || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder="Max"
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }

                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.lastName"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.familyName} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.lastName || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder="Muster"
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                                <FormMessage message={error?.message}/>

                            </FormItem>)}
                        />
                    </FormGroup>

                    <FormGroup>

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.email"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.email} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.email || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            placeholder="max.muster@mail.com"
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }

                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.salutation.id"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.salutation} *

                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {salutations.find((salutation) => salutation.id === selectedDriver?.childCustomer?.salutation?.id)?.description || "-"}
                                        </FormValue>
                                    ) : (
                                        <Select
                                            options={salutations.filter((salutation) => salutation.id !== 0).map((salutation) => ({
                                                value: salutation.id,
                                                label: salutation.description
                                            }))}
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }

                                <FormMessage message={error?.message}/>
                            </FormItem>)}
                        />

                    </FormGroup>


                    <FormGroup>

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.birthday"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.birthday}
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {formatDateIfNotEmpty(selectedDriver?.childCustomer?.birthday) || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            {...field} {...error} type="date"
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.language.id"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.language}
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {getLanguageName(selectedDriver?.childCustomer?.language?.id, labels) || "-"}
                                        </FormValue>
                                    ) : (
                                        <Select
                                            options={languages.map((language) => ({
                                                value: language.id,
                                                label: language.description
                                            }))}
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />
                    </FormGroup>


                    {
                        createDriverForm.watch("existingDriver") ? (
                            <FormItem>
                                <FormLabel>
                                    {labels?.driverPage?.driverInfo?.phone}
                                </FormLabel>
                                <FormValue>
                                    {selectedDriver?.childCustomer?.phone?.phone || "-"}
                                </FormValue>
                            </FormItem>

                        ) : (
                            <FormItem>
                                <FormLabel>
                                    {labels?.driverPage?.driverInfo?.phone}
                                </FormLabel>
                                <FormGroup>
                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.phoneType.id"
                                        render={({field, error}) => (<FormItem style={{width: "250px"}}>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Select options={phoneTypes} {...field} {...error}
                                                    disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />


                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.countryCode"
                                        render={({field, error}) => (<FormItem style={{width: "150px"}}>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Input
                                                {...field} {...error} placeholder="+41"
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />

                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.areaCode"
                                        render={({field, error}) => (<FormItem style={{width: "150px"}}>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Input
                                                {...field} {...error} placeholder="79" type="number"
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />

                                    <FormField
                                        control={createDriverForm.control}
                                        name="childCustomer.phone.cli"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel hidden>
                                                {labels?.driverPage?.driverInfo?.phone}
                                            </FormLabel>
                                            <Input
                                                {...field} {...error} placeholder="123 45 67" type="number"
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                        </FormItem>)}
                                    />

                                </FormGroup>
                            </FormItem>


                        )
                    }


                    <FormGroup>
                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.street"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.street} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.street || "-"}
                                        </FormValue>
                                    ) : (
                                        <>
                                            <Input
                                                {...field} {...error}
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />
                                            <FormMessage message={error?.message}/>
                                        </>

                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.houseNumber"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.houseNumber}
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.houseNumber || "-"}
                                        </FormValue>
                                    ) : (
                                        <Input
                                            {...field} {...error}
                                            disabled={createDriverForm.watch("existingDriver")}
                                        />
                                    )
                                }
                            </FormItem>)}
                        />


                    </FormGroup>

                    <FormGroup>
                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.zip"

                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.zip} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.zip || "-"}
                                        </FormValue>
                                    ) : (
                                        <>
                                            <Input
                                                {...field} {...error} maxLength={5}
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />

                                            <FormMessage message={error?.message}/>

                                        </>

                                    )
                                }
                            </FormItem>)}
                        />

                        <FormField
                            control={createDriverForm.control}
                            name="childCustomer.mainAddress.city"
                            render={({field, error}) => (<FormItem>
                                <FormLabel {...error} >
                                    {labels?.driverPage?.driverInfo?.city2} *
                                </FormLabel>

                                {
                                    createDriverForm.watch("existingDriver") ? (
                                        <FormValue>
                                            {selectedDriver?.childCustomer?.mainAddress?.city || "-"}
                                        </FormValue>
                                    ) : (
                                        <>
                                            <Input
                                                {...field} {...error}
                                                disabled={createDriverForm.watch("existingDriver")}
                                            />

                                            <FormMessage message={error?.message}/>
                                        </>

                                    )
                                }
                            </FormItem>)}
                        />

                    </FormGroup>


                    <FormField
                        control={createDriverForm.control}
                        name="startDt"
                        render={({field, error}) => (<FormItem>
                            <FormLabel {...error} >
                                {labels?.fleetWizard?.form?.driverStartDate} *
                            </FormLabel>
                            <Input {...field} {...error} type="date"/>
                            <FormMessage message={error?.message}/>
                        </FormItem>)}
                    />
                </>)}
            </div>
            {/*Form Control Buttons*/}
            {children[1]}

        </form>
    )
}

export default DriverTab;