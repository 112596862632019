import { useMemo, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { thousandsSeparator } from "../../../actions/formateNumbers.actions";
import { sanitizeValues } from "../../../actions/sanitize";
import { fuelTypeFromCo2Api } from "../../../actions/mapCo2.actions";

export const EmissionsChart = (props ) => {
    let {dataBasedOnWLTP, dataBasedOnFuel} = props
    // I encountered a bug where if you selected a date with no Data, and then selected a range with data again,
    // it would still show the AG Grid/Charts message of 'no data to display', this appears to fix it
    const parsedData = useMemo(() => {
        let tempData = {}

        for (const fuelTypeId of Object.keys(dataBasedOnFuel)) {
            tempData[fuelTypeId]={
                fuelType: props.labels.fleetWizard.form.fuelTypes[fuelTypeFromCo2Api[fuelTypeId]],
                fuelTypeId: fuelTypeId,
                valueBasedOnFuel: dataBasedOnFuel[fuelTypeId],
            }
        }
        for (const fuelTypeId of Object.keys(dataBasedOnWLTP)) {
            if(fuelTypeId in tempData){
                tempData[fuelTypeId].valueBasedOnWLTP = dataBasedOnWLTP[fuelTypeId]
            }else{
                tempData[fuelTypeId]={     
                    fuelType: props.labels.fleetWizard.form.fuelTypes[fuelTypeFromCo2Api[fuelTypeId]],
                    fuelTypeId: fuelTypeId,
                    valueBasedOnWLTP: dataBasedOnWLTP[fuelTypeId]}
            }
        }
        let arrayForm=[...Object.values(tempData)]
        return arrayForm
    }, [dataBasedOnWLTP, dataBasedOnFuel,props.labels.fleetWizard.form.fuelTypes])


    const [chartOptions] = useState({
        data: parsedData,
        autoSize: true,
        height: 200,
        theme: {
            palette: {
                // fills: ['#00008F'], //['#A1A1FF']
                strokes: ['white']
            },
            overrides: {
                common: {
                    axes: {
                        number: {
                            label: {
                                fontFamily: 'Space Grotesk'
                            }
                        },
                        category: {
                            label: {
                                fontFamily: 'Space Grotesk'
                            }
                        }
                    },
                },
            }
        },
        series: [
            {
                type: 'bar',
                xKey: 'fuelType',
                yKey: 'valueBasedOnFuel',
                yName: props.labels.homePage.metrics.tankedFuel,
                cornerRadius: 10,
                fill: '#00008F',
                tooltip: {
                    renderer: (params) => {
                        params = sanitizeValues(params);
                        return {
                            title: "",
                            content:
                                `<div class="type-tooltip">
                            <div class="type-tooltip-title">
                       
                        ${params?.datum?.fuelType}
                     </div> 
                    <div class="type-tooltip-item">
                        ${thousandsSeparator(Math.round((params?.datum?.valueBasedOnFuel || 0) * 100) / 100)} kg
                    </div>
                    </div>`
                        }
                    }
                }
            },
            {
                type: 'bar',
                xKey: 'fuelType',
                yKey: 'valueBasedOnWLTP',
                yName: 'WLTP',
                cornerRadius: 10,
                fill: '#9fc5e8',
                tooltip: {
                    renderer: (params) => {
                        params = sanitizeValues(params);
                        return {
                            title: "",
                            content:
                                `<div class="type-tooltip">
                            <div class="type-tooltip-title">
                       
                        ${params?.datum?.fuelType}
                     </div> 
                    <div class="type-tooltip-item">
                        ${thousandsSeparator(Math.round((params?.datum?.valueBasedOnWLTP || 0) * 100) / 100)} kg
                    </div>
                    </div>`
                        }
                    }
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                line: {
                    width: 0,
                },
                label: {
                    //rotation: 0,
                    avoidCollisions: true,
                }
            },
            {
                type: 'number',
                position: 'left',
                line: {
                    width: 0
                },
                label: {
                    formatter: function (params) {
                        return params?.value;
                        /*
                        Data is not guaranteed to be in the thousands, so I don't see a reason to hardcode 'k' notation,
                        left as comment so we can still change if we want to in future
                        //If value is not 0, return something like "10K"
                        if(params.value > 0) {
                            return Math.floor(params.value / 1000) + "K"
                        }
                        //otherwise return 0, so it's not "0K"
                        return "0"
                         */

                    }
                }

            }
        ],
        legend: {
            position: "right",
        },
    })


    return (
        <AgChartsReact options={chartOptions} />
    )

}